// Warning: this card can cost a lot of resources and slow down the page
// for tenants having a lot of person in DB
// The card is optimised to load the hierarchy only when the card is opened
// or when there are relevant changes that justifies reloading it.
// Make sure to maintain this behavior at all cost.

import axios from "axios"
import { formatPercentage, getFilterFunction, isEqual } from "basikon-common-utils"
import { GraphChart, TreeChart } from "echarts/charts"
import * as echarts from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"
import React, { Component } from "react"
import { ButtonGroup, Col, Row } from "react-bootstrap"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"
import GojsPerson from "@/_components/GojsPerson"

import DiagramAddModal from "@/person/DiagramAddModal"
import PersonDescendantsModals from "@/person/PersonDescendantsModals"

import { getItem, getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { getUser } from "@/_services/userConfiguration"

class PersonHierarchyComponent extends Component {
  constructor(props) {
    super(props)

    const { defaultDepth } = props
    const depth = defaultDepth === undefined ? "All" : isNaN(defaultDepth) ? defaultDepth : defaultDepth.toString()

    this.state = {
      loadingHierarchy: false,
      diagramType: null,
      depth,
      personRelationChart: null,
      personHierarchyChart: null,
      isOldView: true,
      diagramSizeLarge: false,
    }

    this.personHierarchyComponent = null
  }

  componentDidMount() {
    getList("userProfile")
    getList("personRole")
    getList("riskClass")
    getList("personRelation", () => this.setState({ personRelationLoaded: true }))
    window.addEventListener("resize", this.redraw)

    // we let some time pass because the graph might be visible even if the props collapse is true
    // due to the PanelOuter feature that recalls the collapse state from local storage
    setTimeout(() => {
      if (!this.props.collapse && this.isGraphVisible()) this.loadHierarchy(this.props.person)
    }, 400)
  }

  // that's the only way we can make sure the card is expanded or not
  isGraphVisible() {
    // https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes#javascript_access
    return this.personHierarchyComponent?.current?.dataset?.isExpanded === "true"
  }

  // we must only compare the descendants that have been really selected
  // i.e. with the field personRegistration defined otherwise when users just add the relation
  // but have yet picked or created a user this would trigger the hierarchy reload
  hasNewDescendant({ descendants, prevDescendants }) {
    const _descendants = descendants?.filter(descendant => descendant.personRegistration).map(descendant => descendant.personRegistration)
    const _prevDescendants = prevDescendants?.filter(descendant => descendant.personRegistration).map(descendant => descendant.personRegistration)
    return !isEqual(_descendants, _prevDescendants)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.redraw)
  }

  redraw = () => {
    const { isOldView, diagramType } = this.state
    if (isOldView) return
    const chart = this.state[diagramType === "relation" ? "personRelationChart" : "personHierarchyChart"]
    if (chart) setTimeout(() => chart.resize())
  }

  componentDidUpdate(prevProps, prevState) {
    const _hasNewDescendant = this.hasNewDescendant({ descendants: this.props.descendants, prevDescendants: prevProps.descendants })
    if (
      this.props.person?.registration !== prevProps.person?.registration ||
      (this.state.expanded && this.state.expanded !== prevState.expanded) ||
      _hasNewDescendant ||
      this.state.forceTree !== prevState.forceTree ||
      this.state.depth !== prevState.depth ||
      this.state.personRelationLoaded !== prevState.personRelationLoaded ||
      this.state.isOldView !== prevState.isOldView
    ) {
      if (this.state.depth !== prevState.depth) {
        this.setState({ personRelationChartOptions: null, personHierarchyChartOptions: null })
      }

      if (this.isGraphVisible()) {
        // we need to let the opening animation pass before loading the hierarchy again
        // otherwise the chart framework might decide to draw nothing due to the box size being 0
        setTimeout(() => this.loadHierarchy(this.props.person, _hasNewDescendant))
      }
    }
  }

  async loadHierarchy(person, forceReload) {
    const { loadingHierarchy } = this.state
    const { entityServerRoute, diagramRelations } = this.props
    if (loadingHierarchy || !person.descendants) return

    try {
      let personHierarchy = this.state["hierarchy" + person.registration]
      if (!personHierarchy || forceReload) {
        this.setState({ loadingHierarchy: true })
        personHierarchy = (await axios.get(entityServerRoute + "/" + person.registration + "/hierarchy")).data
        if (personHierarchy.organizations) {
          personHierarchy.persons = personHierarchy.organizations
          delete personHierarchy.organizations
        }
      }
      const hierarchy = {
        links: [...personHierarchy.links],
        persons: [...personHierarchy.persons],
      }
      const patch = { hierarchy }
      patch["hierarchy" + person.registration] = personHierarchy

      // add descendant added in screen
      for (let descendant of person.descendants) {
        if (!descendant.personRegistration) continue
        const descendantRegistration = descendant.personRegistration
        if (!hierarchy.persons.find(it => it.registration === descendantRegistration)) {
          let descendantHierarchy = patch["hierarchy" + descendantRegistration] || this.state["hierarchy" + descendantRegistration]
          if (!descendantHierarchy) {
            descendantHierarchy = (await axios.get(entityServerRoute + "/" + descendantRegistration + "/hierarchy")).data
            if (descendantHierarchy.organizations) {
              descendantHierarchy.persons = descendantHierarchy.organizations
              delete descendantHierarchy.organizations
            }
            patch["hierarchy" + descendantRegistration] = descendantHierarchy
          }
          const addedPersons = []
          for (const hperson of descendantHierarchy.persons) {
            if (!hierarchy.persons.find(it => it.registration === hperson.registration)) {
              hierarchy.persons.push(hperson)
              addedPersons.push(hperson.registration)
            }
          }
          for (const hlink of descendantHierarchy.links) {
            if (addedPersons.includes(hlink.source) || addedPersons.includes(hlink.target)) {
              hierarchy.links.push(hlink)
            }
          }
        }
        if (
          !hierarchy.links.find(
            it => it.source === person.registration && it.relation === descendant.relation && it.target === descendantRegistration,
          )
        ) {
          hierarchy.links.push({
            source: person.registration,
            relation: descendant.relation,
            target: descendantRegistration,
            stake: descendant.stake,
          })
        }
      }
      // remove links if not found in current descendants (user has probably deleted them)
      for (let i = hierarchy.links.length - 1; i >= 0; i--) {
        const link = hierarchy.links[i]
        if (link.source === person.registration) {
          if (!person.descendants.find(it => it.personRegistration === link.target && it.relation === link.relation)) {
            hierarchy.links.splice(i, 1)
          }
        }
      }

      hierarchy.personsHash = {}
      for (const person of hierarchy.persons) {
        hierarchy.personsHash[person.registration] = person
      }

      if (diagramRelations?.length) {
        hierarchy.links = hierarchy.links.filter(it => diagramRelations.includes(it.relation))
        hierarchy.persons = hierarchy.persons.filter(p => hierarchy.links.find(l => l.source === p.registration || l.target === p.registration))
      }

      this.setState(patch)
      this.setDiagram(hierarchy, person.registration)
      this.props.onSetHierarchyPersonsState && this.props.onSetHierarchyPersonsState(hierarchy.persons)
    } finally {
      this.setState({ loadingHierarchy: false })
    }
  }

  setDiagram(hierarchy, registration) {
    if (!hierarchy) {
      hierarchy = this.state.hierarchy
      registration = this.state.registration
    }
    if (!hierarchy) return null

    const { isOldView } = this.state

    function setParents(hierarchy) {
      let isTree = true
      for (let person of hierarchy.persons) delete person.parent
      for (let link of hierarchy.links) {
        if (hierarchy.personsHash[link.target]) {
          if (hierarchy.personsHash[link.target].parent) {
            isTree = false
          } else {
            hierarchy.personsHash[link.target].parent = link.source
            hierarchy.personsHash[link.target].relation = link.relation
          }
        }
      }
      return isTree
    }

    function setDepths(hierarchy, rootRegistration) {
      if (!hierarchy.personsHash[rootRegistration]) return
      for (let person of hierarchy.persons) delete person.depth
      hierarchy.depth = 0
      hierarchy.personsHash[rootRegistration].depth = hierarchy.depth
      let touchedPersons = [hierarchy.personsHash[rootRegistration]]
      while (touchedPersons.length) {
        const next = []
        hierarchy.depth++
        for (let person of touchedPersons) {
          for (let link of hierarchy.links) {
            if (
              hierarchy.personsHash[link.source] === person &&
              hierarchy.personsHash[link.target] &&
              hierarchy.personsHash[link.target].depth === undefined
            ) {
              hierarchy.personsHash[link.target].depth = hierarchy.depth
              next.push(hierarchy.personsHash[link.target])
            }
            if (
              hierarchy.personsHash[link.target] === person &&
              hierarchy.personsHash[link.source] &&
              hierarchy.personsHash[link.source].depth === undefined
            ) {
              hierarchy.personsHash[link.source].depth = hierarchy.depth
              next.push(hierarchy.personsHash[link.source])
            }
          }
        }
        touchedPersons = next
      }
      hierarchy.depthValues = []
      if (hierarchy.depth >= 2) {
        for (let i = 1; i < hierarchy.depth - 1; i++) hierarchy.depthValues.push(i.toString())
        hierarchy.depthValues.push("All")
        if (hierarchy.persons.find(it => it.type === "I") && hierarchy.persons.find(it => it.type === "C" || it.type === "O"))
          hierarchy.depthValues.push("Companies")
      }
    }

    let relationTypes = []
    let relationsList = getList("personRelation", () => this.setState({ personRelationLoaded: true }))
    for (let link of hierarchy.links) {
      if (!relationTypes.find(item => item.value === link.relation)) {
        relationTypes.push(relationsList.items[link.relation] || { value: link.relation, label: link.relation + " (???)" })
      }
    }

    function getTooltip(person, username) {
      let tt = loc`Registration` + ": " + person.registration
      if (person.address) tt += "\n" + loc`Address` + ": " + person.address
      if (username) tt += "\n" + loc`Linked to user` + ": " + username
      return tt
    }

    setDepths(hierarchy, this.props.person.registration)
    let { personUsers } = this.props
    let youRegistration = getUser()?.personRegistration
    if (youRegistration && personUsers) personUsers[youRegistration] = getUser().username

    let filterFunction
    if (parseInt(this.state.depth)) {
      const depth = parseInt(this.state.depth)
      filterFunction = it => it.depth <= depth
    } else if (this.state.depth === "Companies") {
      filterFunction = it => it.registration === this.props.person.registration || it.type === "C" || it.type === "O"
    }
    const hierarchySubset = filterFunction
      ? {
          personsHash: hierarchy.personsHash,
          persons: hierarchy.persons.filter(filterFunction),
          links: hierarchy.links.filter(it => filterFunction(hierarchy.personsHash[it.source]) && filterFunction(hierarchy.personsHash[it.target])),
        }
      : hierarchy

    let isTreePossible = setParents(hierarchySubset)
    this.setState({ isTreePossible })

    const includes = this.state.filter ? getFilterFunction(this.state.filter, getLocale()) : () => true
    const checkPersonIsIncluded = person => includes(person.name) || includes(person.registration)

    const includedSet = new Set()
    if (isTreePossible && this.state.forceTree) {
      let diagram =
        hierarchySubset.persons.length === 0
          ? null
          : hierarchySubset.persons.map(person => {
              let color = person.type === "C" || person.type === "O" ? "lightgray" : "lightblue"
              let textColor = "black"
              if (person.registration === registration) {
                color = "gold"
                includedSet.add(person.registration)
              } else {
                if (checkPersonIsIncluded(person)) {
                  includedSet.add(person.registration)
                } else {
                  color = "#F4F4F4"
                  textColor = "#BBBBBB"
                }
              }

              let personUsername = personUsers && personUsers[person.registration]

              let riskClassItem = getItem("riskClass", person.riskClass)
              let topLeftCirclecolor = riskClassItem?.color || "transparent"

              return {
                key: person.registration,
                parent: person.parent,
                name: person.name,
                text: person.name + (personUsername ? "\n" + personUsername : ""),
                tooltip: getTooltip(person, personUsername || person.username),
                color,
                textColor,
                topLeftCirclecolor,
                isMain: person.registration === registration,
              }
            })
      this.setState({ diagramType: "hierarchy", diagram, relationTypes })
    } else {
      let diagram = {}
      diagram.nodeDataArray = hierarchySubset.persons.map(person => {
        let color = person.type === "C" || person.type === "O" ? "lightgray" : "lightblue"
        let textColor = "black"
        if (person.registration === registration) {
          color = "gold"
          includedSet.add(person.registration)
        } else {
          if (checkPersonIsIncluded(person)) {
            includedSet.add(person.registration)
          } else {
            color = "#F4F4F4"
            textColor = "#BBBBBB"
          }
        }

        let personUsername = personUsers && personUsers[person.registration]

        let riskClassItem = getItem("riskClass", person.riskClass)
        let topLeftCirclecolor = riskClassItem?.color || "transparent"

        return {
          key: person.registration,
          name: person.name,
          text: person.name + (personUsername ? "\n" + personUsername : ""),
          tooltip: getTooltip(person, personUsername || person.username),
          color,
          textColor,
          topLeftCirclecolor,
          isMain: person.registration === registration,
        }
      })

      let list = getList("personRelation", () => this.setState({ personRelationLoaded: true }))
      let sortIndex = {}
      list?.values?.forEach?.((it, index) => (sortIndex[it.value] = index))

      let links = hierarchySubset.links.sort(({ relation: a }, { relation: b }) =>
        sortIndex[a] < sortIndex[b] ? -1 : sortIndex[a] === sortIndex[b] ? 0 : 1,
      )

      diagram.linkDataArray = []
      for (let link of links) {
        let { relation } = link
        let label = list?.labels[relation] || relation + "(???)"
        if (link.stake) label += ` (${formatPercentage(link.stake, getLocale())})`
        let existingLink = diagram.linkDataArray.find(it => it.from === link.source && it.to === link.target)
        if (existingLink) {
          existingLink.text += "\n" + label
        } else {
          existingLink = diagram.linkDataArray.find(it => it.from === link.target && it.to === link.source)
          if (existingLink) {
            if (existingLink.backText) {
              // existingLink.backText ? existingLink.backText + "+" + label
            } else {
              existingLink.backText = label
              existingLink.text += "\n" + label
            }
          }
        }
        if (!existingLink) diagram.linkDataArray.push({ from: link.source, to: link.target, text: label })
      }

      // faint links not linked to an active node
      diagram.linkDataArray.forEach(it => {
        it.color = includedSet.has(it.from) || includedSet.has(it.to) ? "black" : "#E9E9E9"
        it.textColor = includedSet.has(it.from) || includedSet.has(it.to) ? "#555555" : "#E9E9E9"
      })

      this.setState({ diagramType: "relation", diagram, relationTypes, hierarchySubset, hierarchy, registration })
    }

    if (!isOldView) setTimeout(() => this.computeChart())
  }

  handleDiagramAddModalClose = (key, what) => {
    this.setState({ showDiagramAddModal: null })
    if (what) this.props.onAddPerson?.(key, what)
  }

  onDiagramAddPerson = (event, node) => {
    const { isOldView } = this.state
    const hierarchy = this.state["hierarchy" + this.props.person.registration]
    const { persons } = hierarchy
    const clickedPerson = isOldView
      ? persons.find(it => it.registration === node.data.key)
      : persons.find(it => it.registration === this.props.person.registration)
    this.setState({ showDiagramAddModal: clickedPerson })
  }

  findChildren = ({ parents, children }) => {
    let hasFoundChild = false
    for (let i = 0; i < parents.length; i++) {
      const parent = parents[i]
      for (let j = 0; j < children?.length; j++) {
        const child = children[j]
        if (child.parent === parent.key) {
          if (!parents[i].children) parents[i].children = []
          parents[i].children.push(child)
          children[j].isFound = true
          hasFoundChild = true
        }
      }
    }

    if (hasFoundChild) {
      const remainingChildren = children.filter(child => !child.isFound)
      for (let i = 0; i < parents?.length; i++) {
        const parent = parents[i]
        if (parent.children) {
          this.findChildren({ parents: parents[i].children, children: remainingChildren })
        }
      }
    }
  }

  computeChart = () => {
    const { diagramType, diagram, hierarchySubset } = this.state
    const { onNodeClicked, person, canAddPerson } = this.props
    echarts.use([GraphChart, TreeChart, CanvasRenderer])

    if (diagramType === "hierarchy") {
      if (this.state.personHierarchyChartOptions) return

      let personHierarchyChart = this.state.personHierarchyChart
      if (!personHierarchyChart) {
        personHierarchyChart = echarts.init(this.refs.refPersonHierarchyChart)
        this.setState({ personHierarchyChart })
      }

      const parents = []
      const children = []
      for (let i = 0; i < diagram?.length; i++) {
        const diagramElement = diagram[i]
        if (diagramElement.parent) {
          children.push(diagramElement)
        } else {
          parents.push(diagramElement)
        }
      }

      this.findChildren({ parents, children })

      const personHierarchyChartOptions = {
        series: [
          {
            type: "tree",
            roam: true,
            id: 0,
            data: [
              {
                name: "root", // tmp hack : we add a fake invisible root so that all nodes are displayed
                label: {
                  show: false,
                },
                symbolSize: 0,
                lineStyle: {
                  width: 0,
                },
                children: parents,
              },
            ],
            symbol: "circle",
            symbolSize: 4,
            itemStyle: {
              border: 0,
              color: "#ADD8E6",
            },
            edgeShape: "polyline",
            edgeForkPosition: "90%",
            expandAndCollapse: false,
            initialTreeDepth: 2,
            lineStyle: {
              color: "black",
              width: 0.5,
            },
            label: {
              position: "left",
              verticalAlign: "middle",
              align: "right",
            },
            leaves: {
              label: {
                position: "right",
                verticalAlign: "middle",
                align: "left",
              },
            },
          },
        ],
      }
      personHierarchyChart.setOption(personHierarchyChartOptions)
      this.setState({ personHierarchyChartOptions })
      this.redraw()
    }

    if (diagramType === "relation") {
      if (this.state.personRelationChartOptions) return

      let personRelationChart = this.state.personRelationChart
      if (!personRelationChart) {
        personRelationChart = echarts.init(this.refs.refPersonRelationChart)
        personRelationChart.on("click", params => {
          if (person.registration === params.data.id) {
            if (canAddPerson) this.onDiagramAddPerson()
          } else {
            this.setState({ personRelationChartOptions: null })
            onNodeClicked && onNodeClicked(params.data.id)
          }
        })
        this.setState({ personRelationChart })
      }

      const riskClassList = getList("riskClass")
      const relationsList = getList("personRelation", () => this.setState({ personRelationLoaded: true }))

      const nodes = []
      for (let i = 0; i < hierarchySubset.persons.length; i++) {
        const { name, registration: personRegistration, type, riskClass } = hierarchySubset.persons[i]
        const node = {
          id: personRegistration,
          name,
          symbolSize: 5,
          itemStyle: {
            color: riskClassList.items[riskClass]?.color || "#D4D4D4",
          },
          label: {
            borderRadius: 3,
            padding: 4,
            fontWeight: "bold",
          },
        }

        if (personRegistration === person.registration) {
          node.label.backgroundColor = "#FFD700"
          node.label.fontWeight = "bold"
        } else if (type === "C" || type === "O") {
          node.label.backgroundColor = "#D3D3D3"
        } else if (type === "I") {
          node.label.backgroundColor = "#ADD8E6"
        }

        nodes.push(node)
      }

      const links = []
      for (let i = 0; i < hierarchySubset.links.length; i++) {
        const link = hierarchySubset.links[i]
        links.push({
          source: link.source,
          target: link.target,
          label: {
            show: true,
            formatter: relationsList.items[link.relation]?.label || link.relation,
            fontSize: 10,
            fontWeight: "bold",
            color: "black",
            rotate: 0,
          },
        })
      }

      const personRelationChartOptions = {
        series: [
          {
            type: "graph",
            layout: "force",
            nodes,
            edgeSymbol: ["none", "arrow"],
            links,
            roam: true,
            lineStyle: {
              color: "black",
            },
            label: {
              show: true,
              position: "center",
              formatter: "{b}",
            },
            force: {
              repulsion: 500,
              layoutAnimation: false,
            },
            zoom: 2,
          },
        ],
      }
      personRelationChart.setOption(personRelationChartOptions)
      this.setState({ personRelationChartOptions })
      this.redraw()
    }
  }

  toggleDiagramSize = () => {
    const { diagramSizeLarge } = this.state
    this.setState({ diagramSizeLarge: !diagramSizeLarge })
    this.redraw()
    this.loadHierarchy(this.props.person)
  }

  switchView = () => {
    this.setState({ isOldView: !this.state.isOldView })
  }

  handleSetFilterState = patch => {
    this.setState(patch, this.setDiagram)
  }

  render() {
    const {
      canAddPerson,
      title = "Relations",
      collapse,
      showPersonQuickAddModal,
      quickAddRelations,
      agreement,
      entityName,
      cardName,
      entityServerRoute,
      showPersonsSearchModal,
      handlePersonsQuickAddModalClose,
      usePersonLayout,
      advancedSearch,
      customSearch,
      customGetEntities,
      customQuickSearch,
      detailed,
    } = this.props
    const modalTriggerOrigin = "fromDiagram"
    const { diagramSizeLarge, loadingHierarchy, diagram, showDiagramAddModal, depth, hierarchy, diagramType, isTreePossible, forceTree, isOldView } =
      this.state

    if (this.props.person && !this.props.person._id) return null

    const action = (
      <>
        <ButtonWithTooltip
          bsStyle="primary"
          bsSize="xs"
          simple={false}
          tooltip="Expand"
          pullRight
          className={"icn-xs " + (diagramSizeLarge ? "icn-collapse" : "icn-expand")}
          btnClassName="mb-5px mt-0 inline-flex-center"
          onClick={this.toggleDiagramSize}
        />

        <ButtonWithTooltip
          bsStyle="primary"
          bsSize="xs"
          simple={false}
          tooltip="Use experimental renderer"
          pullRight
          className="icn-flask icn-xs"
          btnClassName="mb-5px mt-0 inline-flex-center"
          onClick={this.switchView}
          fill={!isOldView}
        />

        {isTreePossible && (
          <ButtonGroup className="pull-right mb-5px mt-0">
            <CustomButton
              ariaLabel="Network view"
              key={1}
              bsStyle="primary"
              bsSize="xs"
              className="inline-flex-center"
              fill={!forceTree}
              onClick={event => {
                event.preventDefault()
                this.setState({ forceTree: false })
              }}
            >
              <i className="icn-share-nodes icn-xs" />
            </CustomButton>
            <CustomButton
              ariaLabel="Tree view"
              key={2}
              bsStyle="primary"
              bsSize="xs"
              className="inline-flex-center"
              fill={forceTree}
              onClick={event => {
                event.preventDefault()
                this.setState({ forceTree: true })
              }}
            >
              <i className="icn-network-wired icn-xs" />
            </CustomButton>
          </ButtonGroup>
        )}

        <ButtonGroup className="pull-right mt-0">
          {hierarchy?.depthValues?.map(it => (
            <CustomButton
              key={it}
              bsStyle="primary"
              bsSize="xs"
              fill={depth === it}
              onClick={event => {
                event.preventDefault()
                this.setState({ depth: it })
              }}
            >
              {loc(it)}
            </CustomButton>
          ))}
        </ButtonGroup>

        <FormInput
          inArray
          fcClassName="pull-right w-25 max-h-24px"
          placeholder="Filter"
          obj={this.state}
          field="filter"
          onSetState={this.handleSetFilterState}
        />
      </>
    )

    return (
      <Card
        collapse={collapse}
        onSetExpanded={expanded => this.setState({ expanded })}
        title={loc(title)}
        className="person-hierarchy-component"
        setRef={ref => (this.personHierarchyComponent = ref)}
        action={action}
      >
        <Row>
          <Col xs={12} className="text-center">
            {loadingHierarchy && <i className="icn-circle-notch icn-spin icn-sm text-black-lightest" />}

            {isOldView && diagram && (
              <GojsPerson
                type={this.state.diagramType}
                size={diagramSizeLarge && diagram && diagram?.nodeDataArray?.length > 10 ? "large" : null}
                diagram={diagram}
                onClick={this.props.onNodeClicked && ((event, node) => this.props.onNodeClicked(node.data.key))}
                onAddPerson={canAddPerson && this.onDiagramAddPerson}
              />
            )}

            <div
              ref="refPersonHierarchyChart"
              className="person-hierarchy-chart"
              data-show={!isOldView && diagramType === "hierarchy"}
              data-is-loading={loadingHierarchy}
            />

            <div
              ref="refPersonRelationChart"
              className="person-relation-chart"
              data-show={!isOldView && diagramType === "relation"}
              data-is-loading={loadingHierarchy}
              data-large-view={diagramSizeLarge && diagram && diagram?.nodeDataArray?.length > 10}
            />
          </Col>
        </Row>

        {showDiagramAddModal && <DiagramAddModal clickedPerson={showDiagramAddModal} onClose={this.handleDiagramAddModalClose} />}

        {/*
          For now this card does not support bbeing instanciated multiple times otherwise several modals would stack when opened.
          To support it, do the same than in the PersonDescendants card.
        */}
        <PersonDescendantsModals
          usePersonLayout={usePersonLayout}
          advancedSearch={advancedSearch}
          customSearch={customSearch}
          customGetEntities={customGetEntities}
          customQuickSearch={customQuickSearch}
          showPersonQuickAddModal={showPersonQuickAddModal?.[0] === modalTriggerOrigin && showPersonQuickAddModal}
          quickAddRelations={quickAddRelations}
          agreement={agreement}
          entityName={entityName}
          cardName={cardName}
          entityServerRoute={entityServerRoute}
          showPersonsSearchModal={showPersonsSearchModal?.[0] === modalTriggerOrigin && showPersonsSearchModal}
          handlePersonsQuickAddModalClose={handlePersonsQuickAddModalClose}
          detailed={detailed}
        />
      </Card>
    )
  }
}

export default PersonHierarchyComponent
